body {
  padding: 20px;
  margin: 0;
}

#root {
  width: 100%;
}

#App {
  width: 100%;
}

.img {
  width: 100%;
}

.center-text {
  text-align: center;
}

.error {
  color: #ff0000;
}

.warning {
  color: #ffa600;
}

.sumary {
  font-size: 3em;
}